import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Tag from "../components/tag"

const BlogPostTemplate = ({ data, location, pageContext }) => {
  const post = data.markdownRemark || data.posts
  const siteTitle = data.site.siteMetadata?.title || `Title`
	const subtitle = data.site.siteMetadata?.subtitle || `Subtitle`
	const frontmatter = post.frontmatter || post
	const tags = frontmatter.tags || []
	const devToUrl = `https://dev.to/kabartolo/${pageContext.slug}`;
	const publishedOnDevTo = (devTo) => {
		return devTo ?
		<em className="published-dev-to">Originally published on
			{` `}
			<a href={devToUrl} target="_blank" rel="noopener noreferrer">
				DEV &#x2197;
			</a>
		</em>
		: null
	}

  return (
    <Layout location={location} title={siteTitle} subtitle={subtitle}>
      <Seo
        title={frontmatter.title}
        description={frontmatter.description || post.description}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
				<div className="post-card-tag-list">
					{tags.map((tag) => (
						<span key={tag} className="postcard-tag"><Tag name={tag} /></span>
					))}
				</div>
        <header>

          <h1 itemProp="headline">{frontmatter.title}</h1>
          <p>{frontmatter.date}</p>
					<div className="published-dev-to">{publishedOnDevTo(post.fields && post.fields.dev_to)}</div>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html || post.fields.html }}
          itemProp="articleBody"
        />
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
				subtitle
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
				tags
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
		posts(id: { eq: $id }) {
			fields {
				html
				dev_to
			}
			title
			date: published_at(formatString: "MMMM DD, YYYY")
			tags: tag_list
			excerpt: description
		}
  }
`
